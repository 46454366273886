<template>
  <div class="d-flex product-facet-filter product-filters">
    <v-select
      v-for="facet in facetsFiltered"
      class="mr-3 default--text"
      :key="facet.name"
      :items="facet.values"
      :item-text="facetLabel"
      :label="facet.name"
      :item-disabled="facetDisabled"
      multiple
      chips
      small-chips
      clearable
      single-line
      outlined
      item-value="id"
      hide-details
      deletable-chips
      @change="handleFacetFilter($event, facet.parameterName)"
      :value="facetsFilter[facet.parameterName]"
      clear-icon="product-filters"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template v-slot:selection="{ item, index }">
        <!-- <v-chip
          v-if="facetsFilter[facet.parameterName].length == 1 && index === 0"
        >
          <span>{{ item.name }}</span>
        </v-chip> -->
        <span
          v-if="facetsFilter[facet.parameterName].length > 0 && index === 0"
        >
          +{{ facetsFilter[facet.parameterName].length }} filtri
        </span>
      </template>

      <!-- <template v-slot:item="data">
        {{ data.item.name }}
      </template> -->
    </v-select>
  </div>
</template>
<style global lang="scss">
.facet-filter {
  .v-select__selections {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    .v-chip {
      overflow: initial;
    }
  }
}
.product-filters {
  .v-input {
    max-width: 200px;
  }
  .v-input__slot {
    min-height: 52px !important;
  }
  .v-text-field--outlined fieldset {
    color: #d5d5d5 !important;
    border-radius: 4px;
  }
}
</style>
<script>
export default {
  name: "ProductFacetFilter",
  //   data() {
  //       return {
  //           selectedFacetsXS : []
  //       }
  //   },
  props: {
    facets: { type: Array },
    facetsFilter: { type: Object },
    hideFilterString: { type: String, default: "" }
  },
  computed: {
    facetFilter: item => {
      return item.filteredCount > 0;
    },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    }
  },
  methods: {
    handleFacetFilter(facets, parameterName) {
      this.$emit("handleFacetFilter", { facets, parameterName });
    },
    facetLabel(item) {
      return item.name + " (" + item.filteredCount + ")";
    },
    facetDisabled: facet => facet.filteredCount == 0
  }
};
</script>

<style></style>
