<template>
  <!-- :label="$vuetify.breakpoint.smAndDown ? $t('products.orderBy') : ''" -->
  <v-row no-gutters align="center" justify="end" class="product-sort-filters">
    <v-select
      color="primary"
      :items="sortOptions"
      :value="sortFilter"
      @change="handleSortFilter"
      :label="$t('products.orderBy')"
      outlined
      hide-details
    >
    </v-select>
  </v-row>
</template>

<script>
export default {
  name: "ProductSortFilter",
  data() {
    return {
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "alf_asc", text: "Descrizione", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "promo",
          text: "In promozione",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  props: { sortFilter: { type: Object } },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  }
};
</script>

<style lang="scss">
.product-sort-filters {
  .v-input__slot {
    min-height: 52px !important;
  }
  .v-text-field--outlined fieldset {
    color: #d5d5d5 !important;
    border-radius: 4px;
  }
}
</style>
